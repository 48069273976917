import React from 'react';
import './styles.css'

const ActionButton = ({label, onClick, disabled, selected}) => {
  const selectedClassName = selected() ? 'selected' : ''
  return (
    <button disabled={disabled()} className={`action-button ${selectedClassName}`} onClick={onClick}>
      {label}
    </button>
  )
}

export default ActionButton;