import React, { Component } from "react";
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  renderCustomComponent,
} from "react-chat-widget";
import io from "socket.io-client";
import "./App.css";

import momentTime from "moment-business-time";

import logo from "./assets/brand-saur-pt.png";
import logo_chat from "./assets/logoSaur.png";
import ActionButton from "./components/ActionButton/ActionButton";
import chatApi from "./lib/chatApi";

const departmentOptions = {
  agribusiness: "Soluções do agronegócio",
  intralogistics: "Soluções de intralogística industrial",
  forest: "Soluções florestais",
};

const socket = io(process.env.REACT_APP_CHAT_API, { path: "/ws/socket.io" });

momentTime.locale("pt-br", {
  workinghours: {
    0: null,
    1: ["08:00:00", "18:00:00"],
    2: ["08:00:00", "18:00:00"],
    3: ["08:00:00", "18:00:00"],
    4: ["08:00:00", "18:00:00"],
    5: ["08:00:00", "18:00:00"],
    6: null,
  },
});

class App extends Component {
  state = {
    department: null,
    userId: null,
  };

  onChangeSector = (department) => {
    const userId = localStorage.getItem("saur360-user-id");

    this.setState({ department, userId }, () => {
      socket.emit("join_chat", userId);
      this.sendUserMessage(departmentOptions[department]);
      if (!momentTime().isWorkingTime()) {
        chatApi.post(`/api/email/${userId}`)
        addResponseMessage(
          `Estamos fora do horário de expediente deixe sua mensagem 
        entraremos em contato o mais rápido possível.`
        );
      } else {
        addResponseMessage(
          "Dentro de instantes nosso Consultor Técnico falará contigo."
        );
      }

    });
  };

  initializeMessages = () => {  
    addResponseMessage(
      `Olá, estamos muito felizes com a sua participação.
      Escolha abaixo uma sala para darmos sequência no seu 
      atendimento:`
    );
    Object.entries(departmentOptions).forEach(([k, v]) => {
      const btnProps = {
        label: v,
        onClick: () => this.onChangeSector(k),
        disabled: () => Boolean(this.state.department),
        selected: () => this.state.department === k,
      };
      renderCustomComponent(ActionButton, btnProps);
    });
    socket.on("ans", (data) => {
      addResponseMessage(data);
    });
  }


  componentDidMount() {
    this.initializeMessages()
  }

  sendUserMessage = (text) => {
    const data = {
      department: this.state.department,
      text: text,
      from_user: true,
      user_id: this.state.userId,
    };

    socket.emit("msg", data);
  };

  handleNewUserMessage = (newMessage) => {
    this.sendUserMessage(newMessage);
  };

  render() {
    return (
      <div className="App">
        <Widget
          senderPlaceHolder="Digite uma mensagem..."
          handleNewUserMessage={this.handleNewUserMessage}
          profileAvatar={logo}
          title={<img src={logo_chat}></img>}
          subtitle=""
        />
      </div>
    );
  }
}

export default App;
